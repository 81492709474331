.App {
  text-align: center;
  position: relative; 
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; */
}

#login-button.login-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 30px;
  font-size: 18px;
  margin: 10px;
  background-color: #007bff; /* Blue color */
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #ffffff5e; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #033468; /* Blue color */
}

.App-link {
  color: #007bff; /* Blue color */
}

@keyframes App-logo-spin {
  0% {
    transform: rotateY(-90deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}
