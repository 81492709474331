/* BEGIN: daform.js CSS */
.daform-container {
    background-color: black;
    color: white;
    font-family: 'Courier New', monospace;
    padding: 20px;
    width: 100%;
    height: 100vh;
    text-align: center; /* Add this line */
}

.daform-input {
    width: 50%;
    padding: 20px;
    margin-bottom: 20px;
    border: wheat;
    background-color: transparent;
    border-bottom: 1px solid white;
    color: white;
}

.daform-submit {
    background-color: white;
    width: 25%;
    color: black;
    border: none;
    padding: 20px 5px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .daform-container {
        padding: 10px;
    }
}

@media screen and (max-width: 480px) {
    .daform-input {
        font-size: 14px;
    }
}
/* END: daform.js CSS */
