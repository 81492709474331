/* FILEPATH: /c:/Users/sumaa/test1/src/HomePage.css */

body {
    margin: 0;
    font-family: sans-serif; /* Set a default font for consistency */
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0; /* Add a subtle background color */
  }
  
  .logo {
    width: 5vw;
  }
  
.sign-out-button {
    cursor: pointer;
    text-decoration: none;
    padding: 10px 20px;
    background-color: black; /* Change background color to black */
    color: #fff;
    border: none; /* Remove default button border */
}
  
  /* Split Screen Layout */
  .container {
    display: flex;
    width: 100%;
    height: 100vh; /* Ensure it takes full height */
  }
  
  .left-side {
    flex: 1;
    background-image: url('https://source.unsplash.com/random?wallpapers'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
  }
  
  .right-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Add padding for spacing */
  }
  
.navigation-button {
    margin-bottom: 20px;
    padding: 30px 40px;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    border: none; /* Remove default button border */
    border-radius: 5px; /* Add rounded corners for visual appeal */
    font-size: 18px; /* Increase the text size to 18 pixels */
}
  
  .navigation-button:hover {
    background-color: #333;
    cursor: pointer; /* Indicate interactivity */
  }
  
  .welcome-message {
  text-align: center; /* Center the message */
  font-size: 24px; /* Increase the font size */
  font-weight: bold; /* Make it bold for emphasis */
  margin-top: 20px; /* Add spacing for visual separation */
  color: #333; /* Set a dark color for readability */
}

/* Optional styling for a more distinct look */
.welcome-message span {
  color: #007bff; /* Highlight the user's name */
}